@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
}

.bg-aa{
  background-image: url('./assets/background/astral.jpg');
}

.head-text::before {
  content: "";
  display: flex;
  width: 4px;
  height: 100%;
  background: #7f46f0;
  margin-right: 12px;
}

.battle-card {
  position: relative;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}

.battle-card:hover:before {
  opacity: 1;
}

.battle-card:hover .info {
  opacity: 1;
}

.battle-card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}

.battle-card .info {
  position: absolute;
  z-index: 3;
  color: #fff;
  opacity: 0;
  transition: 0.5s;
}

.gameload {
  background: rgba(127, 70, 240, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

body {
  /* overflow: hidden; Hide scrollbars */
  background-color:#131519 ;
}

/* Battle Animation Effect */
.container {
  position: absolute;
  width: 20rem;
  height: 20rem;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

/* .container:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 2rem;
  height: 2rem;
  background: rgba(0, 0, 0, 0.25);
  transform: rotate(45deg);
  outline: 10px solid rgba(0, 0, 0, 0.1);
}

.container:nth-child(4n):before,
.container:nth-child(7n):before {
  display: none;
} */

.lds-dual-ring {
  display: inline-block;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}